<template>
  <div
    ref="cursorRoot"
    :class="{ 'cursor--visible': isVisible }"
    class="cursor"
  >
    <div class="cursor-inner">
      <span class="cursor-text" v-html="cursorText" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import throttle from 'raf-throttle'

const cursorText = ref('')
const cursorRoot = ref<HTMLDivElement>()
const isVisible = ref(false)

useEventListener(
  window,
  'mousemove',
  throttle(event => {
    if (!cursorRoot.value) return

    cursorRoot.value.style.setProperty('--x', event.clientX + 'px')
    cursorRoot.value.style.setProperty('--y', event.clientY + 'px')

    const closestCursorText =
      event.target?.closest('[data-cursor]')?.dataset.cursor

    if (closestCursorText) {
      cursorText.value = closestCursorText
      isVisible.value = true
    } else {
      isVisible.value = false
    }
  })
)

useEventListener(document, 'mouseleave', () => {
  isVisible.value = false
})
</script>

<style lang="scss" scoped>
.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z(cursor-circle);

  pointer-events: none;
  user-select: none;
  transform: translate3d(-50%, -50%, 0) translate3d(var(--x), var(--y), 0);
}

.cursor-inner {
  @include size(rem(140px));
  @include center-content;
  background-color: $black;
  border-radius: 50%;

  opacity: 0;

  transition:
    opacity 0.3s $ease-out-cubic,
    transform 0.3s $ease-out-cubic;
  transform: scale(0);

  .cursor--visible & {
    opacity: 1;
    transform: scale(1);
  }
}

.cursor-text {
  font-size: rem(15px);
  font-weight: 700;
  line-height: rem(20px);
  color: $light-grey;
  text-transform: uppercase;
}
</style>
