<template>
  <ClientOnly>
    <Transition name="fade" appear>
      <div v-if="!hasAcceptedCookies" class="cookies grid">
        <div class="banner alternative-focus-outline">
          <p class="content" v-html="nbsp(t('cookie-consent-disclaimer'))" />
          <div class="buttons">
            <BaseButton
              color="primary_light"
              class="button"
              @click="hasAcceptedCookies = true"
            >
              <span class="wildwaffle">{{ t('accept') }}</span>
            </BaseButton>
            <BaseButton
              v-if="settings?.privacy_policy_link"
              color="secondary_light"
              :base-link="settings.privacy_policy_link"
              class="button"
            >
              <span class="wildwaffle">{{ t('privacy-policy') }}</span>
            </BaseButton>
          </div>
        </div>
      </div>
    </Transition>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core'

import { useWebsiteSettings } from '@/state/websiteSettings'

const { t } = useI18n()

const hasAcceptedCookies = useLocalStorage('hasAcceptedCookies', false)
const settings = await useWebsiteSettings()
</script>

<style lang="scss" scoped>
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: z(cookie-bar);
  width: 100%;
}

.banner {
  @include media-column(
    (
      xs: all,
      md: 6.5 -0.5,
      lg: 9.5 -0.5
    )
  );
  display: flex;
  flex-direction: column;
  gap: rem(32px);
  padding: rem(40px) map-get($space-around, xs);
  background-color: $black;

  @include media-up(md) {
    padding: rem(64px) map-get($space-around, md);
  }

  @include media-up(lg) {
    flex-direction: row;
    align-items: center;
    padding: rem(64px) map-get($space-around, lg);
  }

  @include media-up(xxl) {
    padding: rem(64px) map-get($space-around, xxl);
  }
}

.content {
  color: $white-hex;

  @include media-up(lg) {
    max-width: calc(100% - #{rem(449px)});
  }

  @include media-up(xxl) {
    max-width: calc(100% - #{rem(522px)});
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: rem(14px);

  @include media-up(md) {
    gap: rem(16px);
  }
}

.button {
  width: 100%;
  white-space: nowrap;

  @include media-up(md) {
    flex-grow: 1;
    width: auto;
  }

  @include media-up($width: rem(848px)) {
    flex-grow: 0;
  }
}
</style>
