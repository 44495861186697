<template>
  <div class="skip-links">
    <a
      href="#nav"
      class="skip-link t-caption-small"
      v-text="t('a11y.skip-to-main-menu')"
    />
    <a
      href="#main-content"
      class="skip-link t-caption-small"
      v-text="t('a11y.skip-to-content')"
    />
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
</script>

<style lang="scss" scoped>
.skip-link {
  @include focus-visible;
  position: absolute;
  top: 5px;
  left: -999px;
  z-index: z(skip-link);
  padding: 4px 8px;
  color: $orange;
  text-decoration: none;
  background: $black;

  &:focus {
    left: 20px;
  }
}
</style>
